import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as React from 'react'
import OutboundLink from '../../OutboundLink'
import * as style from './style.module.scss'

export default function SnappySlide(props) {
  return (
    <div className={style.mediaContainer}>
      <div className={style.media}>
        {props.image?.childImageSharp ? (
          <GatsbyImage image={getImage(props.image)} />
        ) : (
          <img src={props.image} alt="" />
        )}
      </div>

      <div className={style.copyContainer}>
        {props.title && <div className={style.title}>{props.title}</div>}
        {props.subline && <div className={style.subline}>{props.subline}</div>}
        {props.linkTitle && (
          <OutboundLink
            href={props.link}
            eventLabel={props.eventLabel}
            target="_blank"
          >
            {props.linkTitle}
          </OutboundLink>
        )}
      </div>
    </div>
  )
}
