import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import PricingTable from "../../../../src/content/blog-posts/doggos-vs-rover-vs-wag/PricingTable.js";
import PlaceholderImage from "../../../../src/content/blog-posts/doggos-vs-rover-vs-wag/image1.png";
import Link from 'gatsby-link';
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  PricingTable,
  PlaceholderImage,
  Link,
  React
};