import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import ImageCalendar from "../../../../src/content/blog-posts/getting-started-as-a-dog-sitter/image1.png";
import ImageApproval from "../../../../src/content/blog-posts/getting-started-as-a-dog-sitter/image2.png";
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  ImageCalendar,
  ImageApproval,
  React
};