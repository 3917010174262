import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import HighlightWord from "../../../../src/components/HighlightWord";
import ProngCollarImage1 from "../../../../src/content/blog-posts/prong-collars/prong-collars-1.gif";
import ProngCollarImage2 from "../../../../src/content/blog-posts/prong-collars/prong-collars-2.jpg";
import ProngCollarImage3 from "../../../../src/content/blog-posts/prong-collars/prong-collars-3.gif";
import Link from 'gatsby-link';
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  HighlightWord,
  ProngCollarImage1,
  ProngCollarImage2,
  ProngCollarImage3,
  Link,
  React
};