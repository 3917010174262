import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import SnappySlider from "../../../../src/components/SnappySlider";
import SnappySlide from "../../../../src/components/SnappySlider/SnappySlide";
import Image1 from "../../../../src/content/blog-posts/whats-a-traffic-lead/traffic-lead-1.jpg";
import Image3 from "../../../../src/content/blog-posts/whats-a-traffic-lead/traffic-lead-3.jpg";
import Image4 from "../../../../src/content/blog-posts/whats-a-traffic-lead/traffic-lead-4.jpg";
import Image5 from "../../../../src/content/blog-posts/whats-a-traffic-lead/traffic-lead-5.jpg";
import Image6 from "../../../../src/content/blog-posts/whats-a-traffic-lead/traffic-lead-6.jpg";
import Image7 from "../../../../src/content/blog-posts/whats-a-traffic-lead/traffic-lead-7.jpg";
import Link from 'gatsby-link';
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  SnappySlider,
  SnappySlide,
  Image1,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Link,
  React
};