import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import ImageProfile from "../../../../src/content/blog-posts/how-reviews-work/image1.png";
import ImageTransactionHistory from "../../../../src/content/blog-posts/how-reviews-work/image2.png";
import Link from 'gatsby-link';
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  ImageProfile,
  ImageTransactionHistory,
  Link,
  React
};