import * as React from 'react'
import OutboundLink from '../../OutboundLink'
import * as style from './style.module.scss'

export default function FullWidthImageSliderSlide(props) {
  return (
    <div className={style.mediaContainer}>
      <div className={style.media}>
        <img alt={props.alt} src={props.image} />
      </div>
      <div className={style.copyContainer}>
        {props.title && <div className={style.title}>{props.title}</div>}
        {props.subline && <p className={style.subline}>{props.subline}</p>}
        {props.linkTitle && (
          <OutboundLink href={props.link} eventLabel={props.eventLabel}>
            {props.linkTitle}
          </OutboundLink>
        )}
      </div>
    </div>
  )
}
