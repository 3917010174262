import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import HighlightWord from "../../../../src/components/HighlightWord";
import SnappySlider from "../../../../src/components/SnappySlider";
import SnappySlide from "../../../../src/components/SnappySlider/SnappySlide";
import Slider1 from "../../../../src/content/blog-posts/what-is-mondioring/slider1.png";
import Slider2 from "../../../../src/content/blog-posts/what-is-mondioring/slider2.jpg";
import Slider3 from "../../../../src/content/blog-posts/what-is-mondioring/slider3.jpg";
import Slider4 from "../../../../src/content/blog-posts/what-is-mondioring/slider4.jpg";
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  HighlightWord,
  SnappySlider,
  SnappySlide,
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  React
};