import * as React from 'react'
import Slide from './Slide'
import * as style from './style.module.scss'

export default function FullWidthImageSlider(props) {
  return (
    <div className={style.fullWidthImageSliderWrapper}>
      <div className={style.fullWidthImageSlider} {...props} />
    </div>
  )
}

FullWidthImageSlider.Slide = Slide
