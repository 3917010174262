import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import HighlightWord from "../../../../src/components/HighlightWord";
import ImageHelper from "../../../../src/content/blog-posts/what-is-bitework/1.jpg";
import ImagePuppy from "../../../../src/content/blog-posts/what-is-bitework/2.jpg";
import Link from 'gatsby-link';
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  HighlightWord,
  ImageHelper,
  ImagePuppy,
  Link,
  React
};