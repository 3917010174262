import AuthProvider from '../AuthProvider'
import { MDXProvider } from '@mdx-js/react'
import { useLocation } from '@reach/router'
import algoliasearch from 'algoliasearch/lite'
import { navigate } from 'gatsby'
import qs from 'qs'
import * as React from 'react'
import { InstantSearch } from 'react-instantsearch-dom'
import { Provider as ReakitProvider } from 'reakit'

// import { Auth0Provider } from '../../react-auth0-spa'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const SEARCH_URL_DEBOUNCE_TIME = 400
const SERVER_SIDE_LOCATION = {}

function getSearchParams() {
  var searchParams = {}
  var params = new URLSearchParams(window.location.search)

  params.forEach(function (value, key) {
    searchParams[key] = value
  })

  return searchParams
}

function createURL(state) {
  const params = {
    ...getSearchParams(),
    ...state,
    page: parseInt(state.page, 10) > 1 ? state.page : undefined,
  }

  return `/search/?${qs.stringify(params)}`
}

function searchStateToUrl(searchState) {
  return searchState ? createURL(searchState) : ''
}

function locationToSearchState(location) {
  return qs.parse(location.search.slice(1))
}

function App(props) {
  const [debouncedSetState, setDebouncedSetState] = React.useState(null)
  const [searchState, setSearchState] = React.useState(
    global.location
      ? locationToSearchState(global.location)
      : SERVER_SIDE_LOCATION
  )

  const location = useLocation()
  const onSearchStateChange = (state) => {
    if (location.pathname === '/search/') {
      clearTimeout(debouncedSetState)

      setDebouncedSetState(
        setTimeout(
          () =>
            navigate(searchStateToUrl({ ...searchState, ...state }), {
              replace: true,
            }),
          SEARCH_URL_DEBOUNCE_TIME
        )
      )
    }

    setSearchState(state)
  }

  React.useEffect(() => {
    return () => {
      console.log('SHIT FUCKED UP')
    }
  }, [])

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={('Listings', 'BlogPosts')}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
      refresh={false}
    >
      <MDXProvider>
        <AuthProvider>
          <ReakitProvider>{props.children}</ReakitProvider>
        </AuthProvider>
      </MDXProvider>
    </InstantSearch>
  )
}

export default App
