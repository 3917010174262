import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import SnappySlider from "../../../../src/components/SnappySlider";
import SnappySlide from "../../../../src/components/SnappySlider/SnappySlide";
import ImageCollar from "../../../../src/content/blog-posts/whats-an-agitation-collar/buckle-collar-1.jpg";
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  SnappySlider,
  SnappySlide,
  ImageCollar,
  React
};