import * as style from './style.module.scss'
import * as React from 'react'

function PricingTable() {
  return (
    <>
      <div className={style.table}>
        <div className={style.column}>
          <div className={style.th}>
            <div className={style.logo}>
              doggos
              <span aria-label='ball' className={style.dot} />
              com
            </div>
          </div>
          <div className={style.row}>
            Sitter fee per booking:
            <br /> <strong>No fee! (0%)</strong>
          </div>
          <div className={style.row}>
            Transaction fee (paid by dog owners):
            <br /> <strong>5%</strong> + <strong>3.5%</strong> (Stripe)
          </div>
          <div className={style.row}>
            Total earned in fees:
            <br /> <strong>8.5% (Paid by dog owners only)</strong>
          </div>
        </div>
        <div className={style.column}>
          <div className={style.th}>Rover</div>
          <div className={style.row}>
            Sitter fee per booking:
            <br /> <strong>15-25%</strong>
          </div>
          <div className={style.row}>
            Transaction fee (paid by dog owners):
            <br /> <strong> 11%</strong>{' '}
          </div>
          <div className={style.row}>
            Total earned in fees:
            <br /> <strong>26-36% (Paid by dog owners AND sitters)</strong>
          </div>
        </div>
        <div className={style.column}>
          <div className={style.th}>Wag!</div>
          <div className={style.row}>
            Sitter fee per booking: <br />
            <strong>$14.99-$29.99</strong>
          </div>
          <div className={style.row}>
            Transaction fee (paid by dog owners): <br />
            <strong>
              <strong>$30.00</strong>
            </strong>{' '}
          </div>
          <div className={style.row}>
            Total earned in fees:
            <br />{' '}
            <strong>$45.99-$59.99 (Paid by dog owners AND sitters)</strong>
          </div>
        </div>
      </div>
    </>
  )
}

export default PricingTable
