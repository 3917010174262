import OutboundLink from "../../../../src/components/OutboundLink";
import SnappySlider from "../../../../src/components/SnappySlider";
import SnappySlide from "../../../../src/components/SnappySlider/SnappySlide";
import InsectBasedDogFood1 from "../../../../src/content/blog-posts/insect-based-dog-food/insect-based-dog-food-1.jpg";
import InsectBasedDogFood2 from "../../../../src/content/blog-posts/insect-based-dog-food/insect-based-dog-food-2.jpg";
import InsectBasedDogFood3 from "../../../../src/content/blog-posts/insect-based-dog-food/insect-based-dog-food-3.jpg";
import InsectBasedDogFood4 from "../../../../src/content/blog-posts/insect-based-dog-food/insect-based-dog-food-4.jpg";
import InsectBasedDogFood5 from "../../../../src/content/blog-posts/insect-based-dog-food/insect-based-dog-food-5.jpg";
import * as React from 'react';
export default {
  OutboundLink,
  SnappySlider,
  SnappySlide,
  InsectBasedDogFood1,
  InsectBasedDogFood2,
  InsectBasedDogFood3,
  InsectBasedDogFood4,
  InsectBasedDogFood5,
  React
};