/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const shouldUpdateScroll = ({
  routerProps,
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  if (prevRouterProps?.location?.pathname === routerProps.location.pathname) {
    return false
  }

  return !(routerProps.location.state && routerProps.location.state.query)
}
