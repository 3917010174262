import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import SnappySlider from "../../../../src/components/SnappySlider";
import SnappySlide from "../../../../src/components/SnappySlider/SnappySlide";
import Image1 from "../../../../src/content/blog-posts/5-ways-to-improve-your-dogs-heel/1.jpg";
import * as style from "../../../../src/content/blog-posts/5-ways-to-improve-your-dogs-heel/style.module.scss";
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  SnappySlider,
  SnappySlide,
  Image1,
  style,
  React
};