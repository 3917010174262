import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import OutboundLink from "../../../../src/components/OutboundLink";
import SnappySlider from "../../../../src/components/SnappySlider";
import SnappySlide from "../../../../src/components/SnappySlider/SnappySlide";
import * as React from 'react';
export default {
  FullWidthImageSlider,
  OutboundLink,
  SnappySlider,
  SnappySlide,
  React
};