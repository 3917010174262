import * as React from 'react'
import { usePopoverState, PopoverDisclosure } from 'reakit/Popover'
import Popover from '../Popover'
import * as style from './style.module.scss'

const HighlightWord = (props) => {
  const popover = usePopoverState()

  return (
    <>
      <PopoverDisclosure as="span" {...popover} className={style.highlight}>
        {props.children}
      </PopoverDisclosure>
      <Popover {...popover} aria-label={props.label} tabIndex={0}>
        {props.label}
      </Popover>
    </>
  )
}

export default HighlightWord
