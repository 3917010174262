import FullWidthImageSlider from "../../../../src/components/FullWidthImageSlider";
import Slide from "../../../../src/components/FullWidthImageSlider/Slide";
import ImageProfile from "../../../../src/content/blog-posts/messages-on-doggos/image1.png";
import ImageApproval from "../../../../src/content/blog-posts/messages-on-doggos/image2.png";
import Link from 'gatsby-link';
import * as React from 'react';
export default {
  FullWidthImageSlider,
  Slide,
  ImageProfile,
  ImageApproval,
  Link,
  React
};